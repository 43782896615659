import request from '@/utils/request';

// 退款详情
export function refundDetail(params) {
  return request({
    url: `/organization/refund/verify/detail`,
    method: 'get',
    params
  });
}
