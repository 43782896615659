<template>
  <div class="app-container">
    <div class="nav">
      <div class="nav_title">账单详情</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
        <!-- <span class="back">返回</span> -->
      </div>
    </div>

    <div v-if="item.type === 1" class="content">
      <div class="type">充值详情</div>
      <div class="time">
        <div class="label">充值时间</div>
        <div class="value">{{ item.payTime }}</div>
      </div>
      <div v-if="list.length > 0" class="record-list">
        <div class="record">
          <div class="head">
            <div class="head-title name">订单号</div>
            <div class="head-title">充值金额</div>
            <div class="head-title">充值结果</div>
            <!-- <div class="head-title">扣费状态</div> -->
          </div>
          <div class="body">
            <div v-for="item in list" :key="item.id" class="list">
              <div class="list-item name">{{ item.orderNo }}</div>
              <div class="list-item">{{ currencyTransform(item.amount) }}</div>
              <div class="list-item">
                {{ item.status === 1 ? '失败' : '成功' }}
              </div>
              <!-- <div
              class="list-item"
              :class="item.status === 2 ? 'error' : 'safe'"
            >
              {{ item.status === 2 ? '未缴费' : '已缴费' }}
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="content">
      <div class="type">退款详情</div>
      <div class="time">
        <div class="label">退款时间</div>
        <div class="value">{{ item.payTime }}</div>
      </div>
      <div v-if="list.length > 0" class="record-list">
        <div class="record">
          <div class="head">
            <div class="head-title name">订单号</div>
            <div class="head-title">退款金额</div>
            <div class="head-title">退款结果</div>
            <!-- <div class="head-title">扣费状态</div> -->
          </div>
          <div class="body">
            <div v-for="item in list" :key="item.id" class="list">
              <div class="list-item name">{{ item.outtrxSerialNo }}</div>
              <div class="list-item">
                {{ currencyTransform(item.rejectAmt) }}
              </div>
              <div class="list-item">
                {{ item.returnMsg }}
              </div>
              <!-- <div
              class="list-item"
              :class="item.status === 2 ? 'error' : 'safe'"
            >
              {{ item.status === 2 ? '未缴费' : '已缴费' }}
            </div> -->
            </div>
            <div class="list">
              <div class="list-item name">合计</div>
              <div class="list-item">{{ currencyTransform(total) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import { refundDetail } from '@/api/refund/index';
import { currencyTransform } from '@/utils/util';
export default {
  name: '',
  components: {},
  data() {
    return { cancel, list: [], item: {}, total: 0 };
  },
  async created() {
    this.item = JSON.parse(this.$route.query.item);
    if (this.item.type === 1) {
      this.list.push(this.item);
    } else {
      if (this.item.versionType !== 1) {
        this.item.outtrxSerialNo = this.item.orderNo;
        this.item.rejectAmt = -this.item.amount;
        this.item.returnMsg =
          this.item.refundStatus === 4
            ? '退款成功'
            : this.item.refundStatus === 5
            ? '退款失败'
            : '退款中';
        this.list.push(this.item);
      } else {
        const res = await refundDetail({ refundVerifyId: this.item.id });
        if (res.code === 0) {
          this.list = res.data;
        }
      }
    }
    this.total = this.list.reduce((total, item) => {
      return total + Number(item.rejectAmt);
    }, 0);
    this.total = parseFloat(this.total).toFixed(2);
  },
  mounted() {},
  methods: {
    toBack() {
      this.$router.go(-1);
    },
    currencyTransform
  }
};
</script>

<style lang="scss" scoped>
.nav {
  position: fixed;
  top: 0;
  width: 100%;
  display: block;
  text-align: center;
  height: 48px;
  color: white;
  overflow: hidden;
  border-bottom: 1px solid rgba(181, 201, 210, 0.6);
  background-color: #ffffff;

  & > .nav_title {
    display: block;
    font-size: 1.1rem;
    overflow: hidden;
    white-space: nowrap;
    line-height: 49px;
    text-overflow: ellipsis;
    color: #607483;
    width: 60%;
    margin: 0 auto;
    background-color: #ffffff;
  }

  & > .nav_left_btn {
    float: left;
    position: absolute;
    border-width: 9px 5px 9px 7px;
    border-color: transparent;
    background-size: 12px 1.25rem;
    line-height: 1rem;
    font-size: 1rem;
    color: #607483;
    padding: 0 4px 0 15px;
    top: 9px;
    left: 0;
    min-height: 30px;
    display: flex;
    align-items: center;

    & > .back {
      font-size: 1.1rem;
      margin-left: 3px;
    }
  }

  & > .nav_left_btn:active {
    opacity: 0.5;
  }

  & > .nav_right_btn {
    float: right;
    position: absolute;
    border-width: 9px 4px 9px 3px;
    border-color: transparent;
    background: transparent;
    line-height: 1rem;
    font-size: 1rem;
    color: #607483;
    padding: 0 8px 0 2px;
    top: 8px;
    right: 0;
    min-width: 52px;
    min-height: 30px;
    display: flex;
    align-items: center;
  }

  & > .nav_right_btn_click {
    opacity: 0.5;
  }
}
.content {
  margin: 68px 16px 10px 16px;
  padding: 36px 12px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  .type {
    color: #333333;
    font-size: 18px;
    font-weight: 500;
    font-family: '苹方';
    text-align: center;
    margin-bottom: 40px;
  }
  .time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .label {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      font-family: '苹方';
    }
    .value {
      color: #666666;
      font-size: 16px;
      font-weight: 400;
      font-family: '苹方';
    }
  }
  .record-list {
    margin-top: 10px;
    width: 100%;
    box-sizing: border-box;
    .record {
      background-color: #f7f7f7;

      // 使用grid布局使head和list垂直对齐
      .head,
      .body .list {
        display: grid;
        grid-template-columns: repeat(3, 1fr); // 根据列数进行分配
        /* gap: 10px; */
        align-items: center; // 垂直居中对齐
        color: #333333;
        font-size: 12px;
      }
      .name {
        width: 178px;
      }
      .head {
        font-weight: 500;
        position: relative;
        .head-title {
          box-sizing: border-box;

          /* 居中 */
          display: flex;
          justify-content: center;
          color: #333333;
          font-size: 14px;
          padding: 10px 0; /* 右边框 */
          border-right: 1px solid #e5e5e5;
          /* 每第三个没有有边框 */
          &:nth-child(3n) {
            border-right: none;
          }
        }
      }

      .head::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        transform: scaleY(0.5);
        background-color: #e5e5e5;
      }

      .body {
        .list {
          font-weight: 400;
          .list-item {
            box-sizing: border-box;
            padding: 10px 0;
            height: 100%;
            /* 居中 */
            display: flex;
            justify-content: center;
            /* 允许换行 */
            /* flex-wrap: wrap; */
            /* word-break: break-word; // 处理换行 */

            /* 右边框 */
            border-right: 1px solid #e5e5e5;
            /* 每第三个没有有边框 */
            &:nth-child(3n) {
              border-right: none;
              width: 100%;
            }
          }
        }
      }
      // 针对日期列不换行
      /* .head-title:nth-child(1),
      .list-item:nth-child(1) {
        white-space: nowrap; // 防止换行
      } */
      .head-title:nth-child(3),
      .list-item:nth-child(3) {
        /* 换行 */
        word-break: break-all;

        word-wrap: break-word;
        text-align: center;
      }
    }
  }
}
</style>
